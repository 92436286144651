import React ,{memo} from 'react'

const Loading = () => {
  return (
    <div>
      <h1>Loading...</h1>
    </div>
  )
}

export default memo(Loading)
