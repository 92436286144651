import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import logo from '../assets/Rectangle 33.png';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../css/Home.css";
export const NavbarComp = () => {

  return (
    <>
      <Container fluid className="sticky-top bg-white ">
        <Container>
        {[false,].map((expand) => (
            <Navbar expand="md"  >
              
                <Navbar.Brand href="#">
                  <Link className="navbar " to="/ ">
                    <img src={logo} style={{ width: "70px" }} className="" alt="Logo" />
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className="custom-toggle-button text-secondary "
                >
                  <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav ">
                  <Offcanvas.Body >
                    <Nav className="justify-content-end ms-auto gaps1  " >
                      <Link
                        className="nav-link text-secondary text-black gaps gaps2 "
                        style={{ fontSize: "16px", fontWeight: "640" }}
                        to="/"
                      >
                        INDUSTRIAL
                      </Link>
                      <Link
                        className="nav-link text-secondary text-black gaps"
                        style={{ fontSize: "16px", fontWeight: "640" }}
                        to="/"
                      >
                        PRODUCT
                      </Link>
                      <Link
                        className="nav-link text-secondary text-black gaps"
                        style={{ fontSize: "16px", fontWeight: "640" }}
                        to="/"
                      >
                        UI/UX
                      </Link>
                      <Link
                        className="nav-link text-secondary text-black gaps"
                        style={{ fontSize: "16px", fontWeight: "640" }}
                        to="/"
                      >
                        PROTOTYPE
                      </Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Collapse>
             
            </Navbar>
           
          ))}

        </Container>
    
     
      </Container>
    </>
  );
}

export default NavbarComp;
