import React from "react";
import notFound from "../../src/assets/404.png";
import { Col, Container, Row } from "react-bootstrap";
const NotFound = () => {
  return (
    <>
      <Container fluid>
        <Container>
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Col lg={12} sm={12} xs={8}>
              <img
                src={notFound}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default NotFound;
