import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import UI from '../src/components/Ui/UX'
import Loading from './components/Loading'
import  { NavbarComp } from './components/NavbarComp'
import {Contact,Industrial} from '../src/components'
import NotFound from '../src/components/Not-Found'
const App = () => {
  return (
  <>
  <BrowserRouter>
  <NavbarComp/>
  <Suspense fallback={<Loading />}>
  <Routes>
    <Route path='/' element={<Industrial/>}    />
    <Route path='/contact' element={<Contact/>}    />
    <Route path='/UI' element={<UI/>}    />
    <Route path='*' element={<NotFound/>}    />
  </Routes>
  </Suspense>
  </BrowserRouter>
  </>
  )
}

export default App
